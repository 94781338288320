import { defineComponent } from '@vue/composition-api';

import FlashPlay from '@/components/Article/FlashPlay';

import Card from '@/components/Card';

export default defineComponent({
    components: { FlashPlay, Card },
    setup() {
        return {};
    },
});
