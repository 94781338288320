var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flash-play-component"},[_c('skeleton',{attrs:{"isLoading":_vm.isLoading}},[(!_vm.isError)?_c('div',{staticClass:"flash-play-component-noerror"},[_c('div',{staticClass:"flash-play-header-area"},[_c('h3',[_c('span',[_vm._v(_vm._s(_vm.flashData.title))])]),_c('div',{staticClass:"description-area"},[_c('span',[_vm._v("作者:"+_vm._s(_vm.flashData.author))]),_c('span',[_vm._v("播放量:"+_vm._s(_vm.flashData.playCount))]),_c('span',[_vm._v("点赞量:"+_vm._s(_vm.flashData.praiseCount))]),_c('span',[_vm._v("上传于:"+_vm._s(_vm.parseTime(_vm.flashData.time)))])])]),(!_vm.isLoading && _vm.flashUrl)?_c('div',{staticClass:"flash-play-player-package"},[_c('flash',{attrs:{"src":_vm.flashUrl,"width":_vm.flashWD.w,"height":_vm.flashWD.h,"isPauseMusic":_vm.flashData.hasAudio}})],1):_vm._e(),(
                    !_vm.isError &&
                    !_vm.isLoading &&
                    _vm.flashUrl &&
                    _vm.$store.state.isFlashPlayerActive
                )?_c('div',{staticClass:"flash-container-area"},[_c('div',{staticClass:"progress-container",on:{"click":function($event){$event.preventDefault();return _vm.$message.error(
                            '进度条功能只对Adobe Flash Player有效'
                        )}}},[_c('div',{staticClass:"dur"},[_c('div',{staticClass:"cur"})]),_c('div',{staticClass:"text-area"},[_c('span',[_vm._v("0")]),_c('span',[_vm._v("|")]),_c('span',[_vm._v("-1")])])]),_c('ul',{staticClass:"control-list flex-evenly"},_vm._l((_vm.controlList),function(item){return _c('li',{key:item.onlyid,staticClass:"control-item",class:[
                            ("fctrl-" + (item.onlyid)),
                            item.isMinor ? 'is-minor' : '' ]},[_c('a',{staticClass:"link-block",class:item.disabled ? 'disabled' : 'abled',attrs:{"href":"javascript:;"},on:{"click":function($event){$event.preventDefault();item.disabled
                                    ? _vm.$message.error('该功能暂不支持')
                                    : item.click()}}},[_c('i',{class:("shico-" + (item.icon))}),_c('span',[_vm._v(_vm._s(item.title))])])])}),0)]):_vm._e(),_c('div',{staticClass:"flash-description-area"},[(_vm.flashData.description)?_c('div',{staticClass:"description-text-area"},[_c('h4',[_c('span',[_vm._v("作品简介")])]),_c('vue-showdown',{attrs:{"markdown":_vm.flashData.description,"data-flash-text-area-showdown":""}})],1):_vm._e(),(_vm.flashData.content)?_c('div',{staticClass:"content-text-area"},[_c('h4',[_c('span',[_vm._v("作品寄语")])]),_c('vue-showdown',{attrs:{"markdown":_vm.flashData.content,"data-flash-text-area-showdown":""}})],1):_vm._e()]),(_vm.flashData.isReprint)?_c('div',{staticClass:"flash-reprint-area"},[_c('div',[_c('span',[_vm._v("本作品来自互联网，非站长本人原创，如有侵权，可及时告知，站长会第一时间删除。")])]),(_vm.reprintInfo.author)?_c('div',[_c('span',{staticClass:"k"},[_vm._v("原作者")]),_c('span',{staticClass:"v"},[_vm._v(_vm._s(_vm.reprintInfo.author))])]):_vm._e(),(_vm.reprintInfo.posted)?_c('div',[_c('span',{staticClass:"k"},[_vm._v("发表在")]),_c('span',{staticClass:"v"},[_vm._v(_vm._s(_vm.reprintInfo.posted))])]):_vm._e(),(_vm.reprintInfo.url)?_c('div',[_c('span',{staticClass:"k"},[_vm._v("转载来源")]),_c('a',{staticClass:"v",attrs:{"href":_vm.reprintInfo.url,"target":"_blank","title":_vm.reprintInfo.url}},[_vm._v(_vm._s(_vm.reprintInfo.url))])]):_vm._e()]):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],staticClass:"flash-countinfo-area"},[_c('div',[_c('span',[_vm._v("播放量:"+_vm._s(_vm.flashData.playCount))]),_c('span',[_vm._v("点赞量:"+_vm._s(_vm.flashData.praiseCount))])])])]):_vm._e()]),(_vm.isError)?_c('div',[_c('span',[_vm._v(_vm._s(("系统出错:" + _vm.errorMsg)))])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }