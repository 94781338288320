import { defineComponent, nextTick, onBeforeUnmount, onMounted, reactive, ref } from '@vue/composition-api';
import type { Ref } from '@vue/composition-api';

import { Skeleton } from '@/components/UI';

import Flash from '@/components/Flash';
import { req } from '@/api';

import { parseTime } from '@/public/methods';

import store from '@/store';

interface ControlList {
	onlyid: string;
	title: string;
	click: () => void;
	icon: string | any;
	isMinor?: boolean;
	disabled?: boolean;
}

export default defineComponent({
	components: { Flash, Skeleton },
	setup(props, { root }: any) {
		const { $message, $route } = root;
		const isLoading: Ref<boolean> = ref(true);
		const isError: Ref<boolean> = ref(false);
		const errorMsg: Ref<string> = ref('');
		const flashData: Ref<any> = ref({
			onlyid: '',
			title: '标题区域',
			category: '',
			author: '',
			authorUrl: '',
			backImg: '',
			time: -1,
			timePrecise: '',
			description: '',
			content: '',
			flashUrl: '',
			isReprint: true,
			reprintFrom: '',
			hasAudio: true,
			durationFrame: -1,
			playCount: -1,
			clickCount: -1,
			praiseCount: -1,
		});
		const flashUrl: Ref<string> = ref('');
		const flashWD = reactive({ w: '550px', h: '400px' });
		const reprintInfo: Ref<any> = ref({
			author: '',
			posted: '',
			url: '',
		});
		const changeWD = (scale: number) => {
			if (typeof scale !== 'number' || isNaN(scale) || scale < 0.3 || scale > 3) {
				scale = 550 / 400;
			}
			let w = 550,
				h = 400;
			const clientWidth = document.documentElement.clientWidth || document.body.clientWidth;
			if (clientWidth < 600 && clientWidth > 50) {
				w = clientWidth - 25;
			} else if (clientWidth > 1366) {
				w = 993;
			}
			h = w / scale;
			flashWD.w = '' + Math.floor(w) + 'px';
			flashWD.h = '' + Math.floor(h) + 'px';
		};
		onMounted(() => {
			req('FLASH_FLASH', { onlyid: $route?.params?.onlyid || '' })
				.then(data => {
					//console.log('Flash信息', data);
					flashData.value = Object.assign(flashData.value, data);
					//映射flash的URL
					flashUrl.value = (data as any)?.flashUrl || '';
					//映射flash的宽高
					changeWD(Number((data as any)?.scale) || -1);
					//console.log(flashData.value.flashUrl);
					//注册至VUEX状态
					store.commit('registerFlashInfo', data);
					//更改标题
					flashData.value.title &&
						store.commit('changeMetaInfo', {
							title: flashData.value.title + ' - ' + store.state.metaInfo.title,
						});
				})
				.catch(error => {
					isError.value = true;
					errorMsg.value = '' + error;
					$message.error(error);
				})
				.finally(() => {
					nextTick(() => {
						isLoading.value = false;
					});
				});
		});
		let isLoved = false;
		onBeforeUnmount(() => {
			store.commit('registerFlashInfo', -1);
		});
		/*
        if (
            document.documentElement.clientWidth < 600 &&
            document.documentElement.clientWidth > 50
        ) {
            flashWD.w =
                Math.ceil(document.documentElement.clientWidth - 25) + 'px';
            flashWD.h =
                Math.ceil((document.documentElement.clientWidth - 25) / 1.375) +
                'px';
        }
        if (document.documentElement.clientWidth > 1400) {
            flashWD.w = '825px';
            flashWD.h = '600px';
        }
*/
		//控制区域的功能
		const controlList: Ref<ControlList[]> = ref([
			{
				onlyid: 'play',
				title: '播放',
				click: () => {
					store.commit('flashPassive', 'play');
				},
				icon: 'play',
			},
			{
				onlyid: 'pause',
				title: '暂停',
				click: () => {
					store.commit('flashPassive', 'pause');
				},
				icon: '',
			},
			{
				onlyid: 'stop',
				title: '停止',
				click: () => {
					//
				},
				icon: '',
				isMinor: true,
				disabled: true,
			},
			{
				onlyid: 'pre_frame',
				title: '上一帧',
				click: () => {
					//
				},
				icon: '',
				isMinor: true,
				disabled: true,
			},
			{
				onlyid: 'next_frame',
				title: '下一帧',
				click: () => {
					//
				},
				icon: '',
				isMinor: true,
				disabled: true,
			},
			{
				onlyid: 'scene',
				title: '场景',
				click: () => {
					//
				},
				icon: '',
				isMinor: true,
				disabled: true,
			},
			{
				onlyid: 'love',
				title: '喜欢',
				click: () => {
					const successMsg = '点赞成功';
					const failMsg = '点一次就够啦';
					if (isLoved) {
						$message.warning(failMsg);
						return;
					}
					let success = true;
					flashData.value.praiseCount += 1;
					req('FLASH_LOVE', { onlyid: $route?.params?.onlyid || '' })
						.then((data: any) => {
							if (data?.success) {
								$message.success(data?.msg || successMsg);
							} else {
								success = false;
								$message.warning(data?.msg || failMsg);
							}
						})
						.catch(error => {
							success = false;
							$message.error(error);
						})
						.finally(() => {
							if (!success) {
								flashData.value.praiseCount -= 1;
							}
						});
					isLoved = true;
				},
				icon: '',
			},
			{
				onlyid: 'share',
				title: '分享',
				click: () => {
					//
				},
				icon: '',
				isMinor: true,
				disabled: true,
			},
			{
				onlyid: 'download',
				title: '下载',
				click: () => {
					window.open(flashUrl.value);
				},
				icon: '',
				isMinor: true,
			},
			{
				onlyid: 'light',
				title: '关灯',
				click: () => {
					//
				},
				icon: '',
				isMinor: true,
				disabled: true,
			},
			{
				onlyid: 'fullscreen',
				title: '全屏',
				click: () => {
					store.commit('flashPassive', 'fullscreen');
				},
				icon: '',
			},
		]);
		return {
			flashWD,
			isLoading,
			isError,
			errorMsg,
			flashData,
			flashUrl,
			reprintInfo,
			parseTime,
			controlList,
		};
	},
});
